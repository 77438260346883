<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Ship From(Mill)" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label='REGION' clearable dense :disabled="disabled_region" @change="(event) => regionOnChange(event)" prepend-icon="mdi-city"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label='BRANCH OFFICE' clearable dense :disabled="disabled_office" @change="(event) => officeOnChange(event)" prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label='SALESMAN' :disabled="disabled_salesman" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-text-field v-model="orderIdModel" label="ORDER ID" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Ship Date" readonly v-bind="attrs" v-on="on" dense clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-12 text-right">
                                <v-btn class="" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :loading="loadingDatatable" dense>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip @click="showItemDetails(item)" color="blue" link small v-on="on" dark>Details</v-chip>
                                    </template>
                                    <span>Show Detail</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.dt_leave`]="{ item }">
                                <div v-if="item.dt_leave != '01/01/1900 00:00'">
                                    {{ item.dt_leave }}
                                </div>
                            </template>
                            <template v-slot:[`item.dt_home`]="{ item }">
                                <div v-if="item.dt_home != '01/01/1900 00:00'">
                                    {{ item.dt_home }}
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>

        </div>

        <v-dialog v-model="dialogDetail" max-width="80%">
            <div class="card elevation-5">
                <div class="card-body">
                    <v-data-table :headers="headersDetail" :items="itemListsDetail" class="elevation-1" page-count="5" :loading="loadingDatatableDetail" dense>
                        <template v-slot:item.no="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template v-slot:[`item.dt_drop`]="{ item }">
                            <div v-if="item.dt_drop != '01/01/1900 00:00'">
                                {{ item.dt_drop }}
                            </div>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip @click="showItemDetailsProduct(item)" color="blue" link small v-on="on" dark>Details</v-chip>
                                    <v-chip @click="showMap(item)" color="blue" class="ml-2" link small v-on="on" dark>Map</v-chip>
                                </template>
                                <span>Show Detail</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </div>
            </div>
        </v-dialog>

        <v-dialog v-model="dialogDetailProduct" max-width="50%">
            <div class="card elevation-5">
                <div class="card-body">
                    <v-data-table :headers="headersDetailProduct" :items="itemListsDetailProduct" class="elevation-1" page-count="5" :loading="loadingDatatableDetailProduct" dense>
                    </v-data-table>
                </div>
            </div>
        </v-dialog>

        <v-dialog v-model="dialogDetailMap" max-width="80%">
            <div class="card elevation-5">
                <div class="card-body">
                    <div id="mapCanvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                </div>
            </div>
        </v-dialog>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Driver',
                disabled: false,
                href: '#',
                },
                {
                text: 'Today Delivery',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            regionModel: '',
            regionLists: [],
            disabled_region: true,
            officeModel: '',
            officeLists: [],
            disabled_office: true,
            salesModel: '',
            salesLists: [],
            disabled_salesman: true,
            orderIdModel: '',
            dateStartModal: false,
            dateStartModel: '',
            dateStartFormatted: '',
            headers: [
                { text: 'MILL', value: 'mill_name', align: 'left', class: 'primary--text blue lighten-5' },
                // { text: 'SHIP. ID', value: 'tr_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'VEHICLE ID', value: 'vehicle_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DRIVER', value: 'Nama', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SHIP. DATE', value: 'ship_date', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'START', value: 'dt_leave', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'END', value: 'dt_home', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'action', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            itemLists: [],
            loadingDatatable: false,
            dialogDetail: false,
            headersDetail: [
                // { text: 'DELIV. ID', value: 'deliv_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'ORDER ID', value: 'order_id', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVER', value: 'receiver', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SHIP TO', value: 'shipto', align: 'left', class: 'primary--text blue lighten-5' },
                // { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DROP', value: 'dt_drop', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'action', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            itemListsDetail: [],
            loadingDatatableDetail: false,
            dialogDetailProduct:  false,
            headersDetailProduct: [
                { text: 'ITEM NO.', value: 'item_num', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DESCR.', value: 'descr', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'length_ship', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'QTY', value: 'qty_ship', align: 'left', class: 'primary--text blue lighten-5' },
            ],
            itemListsDetailProduct: [],
            loadingDatatableDetailProduct : false,
            dialogDetailMap: false,
            mymap: ''
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            
            this.$store.dispatch('setOverlay', true)
            // document.querySelector(".cardResult").style.display = "none"

            this.dateStartModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/DriverActivity`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millLists = res.data.mill
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.labelBranch = 'Branch Office(' + res.data.countOffice + ')'
                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales
                this.labelSales = 'Salesman(' + res.data.countSales + ')'

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        async getData() {

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            this.itemLists = []
            document.querySelector(".cardResult").style.display = "block";
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DriverActivity/getData`, { 

                mill: this.millModel ? this.millModel : '',
                region: this.regionModel ? this.regionModel : '',
                office: this.officeModel ? this.officeModel : '',
                sales: this.salesModel ? this.salesModel : '',
                order_id: this.orderIdModel ? this.orderIdModel : '',
                dtShip: this.dateStartFormatted ? this.dateStartFormatted : '',

            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                if(res.data.result.length != 0){
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        icon: 'warning',
                        text: 'Data not found !'
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        async showItemDetails(id){

            
            this.dialogDetail = true
            this.loadingDatatableDetail = true
            this.itemListsDetail = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DriverActivity/getDataDetail`, { 

                id: id.tr_id,

            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.loadingDatatableDetail = false
                this.itemListsDetail = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        async showItemDetailsProduct(id){

            console.log(id.deliv_id);

            this.dialogDetailProduct = true
            this.loadingDatatableDetailProduct = true
            this.itemListsDetailProduct = []

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/DriverActivity/getDataDetailProduct`, { 

                id: id.deliv_id,

            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                
                this.loadingDatatableDetailProduct = false
                this.itemListsDetailProduct = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },

        async showMap(id){

            console.log(id.lat);

            if (this.mymap != '') {
                this.mymap.off()
                this.mymap.remove()
            }

            // $('#detailModal').modal('show')
            this.dialogDetailMap = true

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${id.lat}&lon=${id.long}`)
            .then(res => {

                this.mymap = L.map('mapCanvas').setView([id.lat, id.long], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([id.lat, id.long],
                    {draggable: false,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup(id.receiver)
                    .openPopup();

            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>